@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  padding: @padding-xs @padding-sm 0;

  :global {
    .ant-menu-item {
      border-radius: @border-radius-md;
    }
    .ant-menu-item-disabled {
      pointer-events: none;
    }

    // for some reason the component does not
    // pick up the theme color so making a stronger rule here
    .ant-menu-item-selected {
      background-color: @turquoise-2 !important;
    }
  }
}

.Chat {
  background-color: @turquoise-2;
  color: @teal-8;

  &:hover {
    color: @connecterra-teal !important;
  }

  :global {
    svg {
      color: @teal-8;
    }
  }
}

.Collapse {
  color: @gray-6;
  margin-top: auto !important;
}

.ChatUnselected {
  :global {
    svg {
      color: @gray-7;
    }
  }
  &:hover {
    svg {
      color: @teal-8 !important;
    }
  }
}

.Tour {
  margin: @margin-sm 0;
}
