@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.EventEntry {
  padding: 0;

  :global {
    .ant-collapse {
      #border.clear();

      width: 100%;
      position: relative;

      .ant-collapse-header {
        background-color: @white;
        display: flex;
        align-items: center;
        padding: @padding-sm @padding-md;
      }

      .ant-collapse-item {
        #border.clear();
      }

      .ant-collapse-content-box {
        padding: @padding-sm @padding-md;
      }

      .ant-collapse-header-text {
        line-height: 0;
        flex: auto !important;

        .ant-space-item {
          line-height: normal;

          &:last-child {
            margin-right: @margin-lg;
          }
        }

      }

      .ant-collapse-expand-icon {
        position: absolute;
        right: @padding-md;
      }
    }

    .ant-space-item svg {
      #dimensions.square-dimensions(30px);

      path {
        fill: @gray-6;
      }

      circle {
        fill: @teal-1;
      }
    }
  }
}

.NoDescription {
  :global {
    .ant-collapse {
      .ant-collapse-header {
        cursor: default;
      }

      .ant-collapse-expand-icon {
        display: none;
        position: absolute;
      }
    }
  }
}

.Small {
  font-size: @font-size-sm !important;
}