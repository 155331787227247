@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Wrapper {
  width: 50%;

  @media @small-desktop {
    width: 100%;
  }

  @media @tablet {
    width: 100%;
  }

  @media @phone {
    width: 100%;
  }
}