@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.ListItem {
  background-color: @white;
  cursor: pointer;
}

.Col {
  padding: @padding-md;
}

.Icon {
  :global {
    .anticon {
      display: block;
    }

    svg {
      #dimensions.square-dimensions(28px);
    }
  }
}

.Title {
  color: @gray-8;
}

.Seen {
  visibility: hidden;
}

.Caret {
  color: @gray-8;
}
