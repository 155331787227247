@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
@radius: @border-radius-lg * 2;

.EventList {
  #border.base(all);
  background-color: @gray-2;
  border-radius: @radius;
  overflow: hidden;
  :global {
    .ant-list-header {
      #border.with-color(@gray-3, bottom);
      background-color: @white;
      padding: @padding-sm @padding-md;
      font-weight: 500;
      border-radius: @radius @radius 0 0;
    }
  }
}
