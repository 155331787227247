@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Breadcrumb {
  li:last-child,
  li:last-child a {
    font-weight: bold;
  }

  :global {
    .ant-breadcrumb-separator {
      position: relative;
      top: -1px;
    }
  }
}

.PullRight {
  margin-left: auto;
}

.Branding {
  flex: 1;
  height: 100%;
  overflow: hidden;
  padding: 0;
  position: relative;

  :global {
    .ctra-branding-icon {
      display: block;
      fill: @turquoise-5;
      height: 36px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
