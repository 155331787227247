@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.RolePickWrapper {
  margin: @margin-xxl auto;
  width: 50%;

  @media @phone {
    width: 90%;
  }

  :global {
    .ant-space-vertical {
      width: 100%;
    }
  }
}

.PageWrapper {
  margin: @margin-xxl;
}