@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Chart {
  overflow: visible;
  background-color: @white;
}

.Mini {
  background-color: @white;
  overflow: hidden;
}

.Header {
  :global {
    .ant-page-header {
      padding: 0 @padding-sm @padding-sm 0;
      margin-top: -@margin-sm;
    }
  }
}

.Standalone {
  #border.base(all, 2px);

  :global {
    //.ctra-chart-content {
    //  margin-top: @margin-md;
    //}

    .ctra-chart-extension {
      #border.clear();
      height: 100%;
      padding: 0;

      & > .ant-spin-nested-loading {
        height: 100%;

        & > .ant-spin-container {
          height: 100%;
        }
      }
    }

    .ctra-chart-content {
      .ant-spin-nested-loading {
        width: 100%;
      }
    }
  }

  @media @phone {
    #border.clear(top);
  }
}

.V2 {
  border: none;
  border-radius: @border-radius-lg * 2;

  :global {
    //.ctra-chart-content {
    //  margin-top: @margin-md;
    //}

    .ctra-chart-extension {
      height: 100%;
      padding-left: 0;

      & > .ant-spin-nested-loading {
        height: 100%;

        & > .ant-spin-container {
          height: 100%;
        }
      }
    }

    .ctra-chart-content {
      .ant-spin-nested-loading {
        width: 100%;
      }
    }
  }
}

.Filter {
  margin-bottom: @margin-md;
  padding-bottom: 0;

  :global {
    .ant-page-header-content {
      padding: 0;
    }
  }
}

.Content {
  margin-bottom: @margin-md;
}
