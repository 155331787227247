@import "~@ctra/components/src/style/theme.less";
@import "~@ctra/components/src/style/mixins.less";
.Base {
  display: flex;
  align-items: center;
  outline: none;
  border: none;
  box-shadow: none;
  transition: none;
}

.Group {
  :global {
    .ant-menu-item-group-title {
      padding: @padding-sm;
    }
    .ant-menu-item {
      #border.base(bottom);

      margin: 0 !important;
      padding: 0 @padding-lg;

      &:first-child {
        #border.base(top);
      }
    }
    .ant-menu-item-selected {
      background-color: inherit !important;
    }
  }
}